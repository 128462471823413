import * as types from './actionTypes';
import * as utils from '../../utils';
import history from '../../components/history';
import { openModal } from './modals';
import { loadUserDetail } from './users';

const loadBotsSuccess = bots => ({ type: types.LOAD_BOTS_SUCCESS, payload: bots });
const loadBotsFailure = error => ({ type: types.LOAD_BOTS_FAILURE, payload: error });

const loadingBots = () => ({ type: types.BOTS_LOADING });
const loadingBotMembers = () => ({ type: types.BOT_MEMBERS_LOADING });

const loadUserBotsSuccess = bots => ({ type: types.LOAD_USER_BOTS_SUCCESS, payload: bots });

const searchBotsSuccess = bots => ({ type: types.SEARCH_BOTS_SUCCESS, payload: bots });
const searchBotsFailure = error => ({ type: types.SEARCH_BOTS_FAILURE, payload: error });

const clearAddBotErrors = () => ({ type: types.CLEAR_USER_ERRORS });

const botError = error => ({ type: types.BOT_ERROR, payload: error });
const clearBotError = () => ({ type: types.CLEAR_BOT_ERROR });

const addBotFailure = error => ({ type: types.ADD_BOTS_FAILURE, payload: error });
const addBotSuccess = bot => ({ type: types.ADD_BOTS_SUCCESS, payload: bot });
const updateBotSuccess = bot => ({ type: types.UPDATE_BOTS_SUCCESS, payload: bot });
const clearBotsSuccess = () => ({ type: types.CLEAR_BOTS_SUCCESS });
const getErrorMessage = error => ((error.response) ? error.response.dataMessage : error.message);

const showPasswordSuccess = password => ({ type: types.SHOW_BOT_PASSWORD_SUCCESS, payload: password });


const loadBot = bot => ({ type: types.LOAD_BOT_SUCCESS, payload: bot });

export const clearUsers = () => (dispatch) => {
  dispatch(clearBotsSuccess());
};

export const clearFormErrors = () => (dispatch) => {
  dispatch(clearAddBotErrors());
};

const parseFetchResponse = response => response.json().then(text => ({
  json: text,
  meta: response,
}));


const fetchBotDetail = async (dispatch, botId, api, getState) => {
  const { selectedBot } = getState().bots;
  const bot = await api.get(`/users/${botId}`);
  if (!selectedBot) {
    bot.groups = [];
    bot.users = [];
  }
  const { user } = getState().auth;
  let botPassword = null;
  if (user.appRole === 'ext') {
    botPassword = 'External users are not allowed to view bot password!';
    dispatch(showPasswordSuccess(botPassword));
  } else {
    try {
      botPassword = await api.get(`/bots/${bot.uuid}/password`, true);
    } catch (error) {
      botPassword = 'Error loading bot password!';
      dispatch(showPasswordSuccess(botPassword));
    }
  }

  return { ...selectedBot, ...bot, password: botPassword };
};

export const loadBotDetail = botId => async (dispatch, getState, api) => {
  try {
    dispatch(loadingBotMembers());
    const bot = await fetchBotDetail(dispatch, botId, api, getState);
    await dispatch(loadBot(bot));
  } catch (error) {
    dispatch(searchBotsFailure(getErrorMessage(error)));
  }
};

export const getSelectedBotUsers = () => async (dispatch, getState, api) => {
  try {
    dispatch(loadingBotMembers());
    const { selectedBot } = getState().bots;
    const users = await api.get(`/bots/${selectedBot.uuid}/members/all`);
    if (getState().bots.selectedBot) {
      dispatch(loadBot({ ...selectedBot, users: users.botMembers }));
    }
  } catch (error) {
    dispatch(searchBotsFailure(getErrorMessage(error)));
  }
};

export const getSelectedBotGroups = () => async (dispatch, getState, api) => {
  try {
    dispatch(loadingBotMembers());
    const { selectedBot } = getState().bots;
    const allGroups = await api.get(`/users/${selectedBot.uuid}/groups?nested=true`);
    const onlyDirectMemberships = await api.get(`/users/${selectedBot.uuid}/groups?nested=false`);
    allGroups.forEach((group) => {
      const index = onlyDirectMemberships.findIndex(directGroup => directGroup.group.uuid === group.group.uuid);
      if (index < 0) {
        // eslint-disable-next-line no-param-reassign
        group.isParentGroup = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        group.isParentGroup = false;
      }
      // eslint-disable-next-line no-param-reassign
      group.childGroups = [];
    });
    allGroups.forEach((group) => {
      if (group.groupParentNames && group.groupParentNames.length) {
        group.groupParentNames.forEach((parentGroupName) => {
          const parentGroup = allGroups.find(childGroupFinding => childGroupFinding.group.name === parentGroupName);
          if (parentGroup) {
            parentGroup.childGroups.push(group.group.name);
          }
        });
      }
    });
    if (getState().bots.selectedBot) {
      dispatch(loadBot({ ...selectedBot, groups: allGroups }));
    }
  } catch (error) {
    dispatch(searchBotsFailure(getErrorMessage(error)));
  }
};

export const getBots = params => async (dispatch, getState, api) => {
  try {
    dispatch(loadingBots());
    const urlParams = new URLSearchParams({ ...params });
    const response = await api.get(`/v2/bots?${urlParams}`);
    response.bots.sort((a, b) => {
      const x = a.username.toLowerCase();
      const y = b.username.toLowerCase();
      if (x < y) { return -1; }
      if (x > y) { return 1; }
      return 0;
    });
    dispatch(loadBotsSuccess(response));
  } catch (error) {
    dispatch(loadBotsFailure(getErrorMessage(error)));
  }
};


export const getUserBots = () => async (dispatch, getState, api) => {
  try {
    const userId = getState().auth.user.uuid;
    const response = await api.get(`/users/${userId}/bots`);
    dispatch(loadUserBotsSuccess(response));
  } catch (error) {
    dispatch(loadBotsFailure(getErrorMessage(error)));
  }
};

export const searchBot = searchStr => async (dispatch, getState, api) => {
  try {
    const response = await api.get('/bots');
    const regex = new RegExp(searchStr, 'ig');
    const users = response.filter(user => (user.isBot && (
      regex.test(user.username)
      || regex.test(user.email))));
    dispatch(searchBotsSuccess(users));
  } catch (error) {
    dispatch(searchBotsFailure(getErrorMessage(error)));
  }
};

export const addBot = data => async (dispatch, getState, api) => {
  try {
    const payload = {
      ...data,
      isBot: true,
      firstName: 'bot',
      lastName: 'bot',
      email: data.email ? data.email : `${data.username}@rtm.invalid`,
    };
    const response = await api.postbody('/users', payload);

    const { json, meta } = await parseFetchResponse(response);
    if (meta.status === 201) {
      await dispatch(getUserBots());
      const botPassword = await api.get(`/bots/${json.uuid}/password`, true);
      const botMembers = await api.get(`/bots/${json.uuid}/members/all`);
      dispatch(addBotSuccess({
        ...json, password: botPassword, ...{ users: botMembers },
      }));
    } else {
      dispatch(addBotFailure(json.message));
      throw Error(json.message);
    }
  } catch (error) {
    throw Error(error.message);
  }
};

const updateBotPassword = async (data, currentPassword, api) => {
  if (!data.password || currentPassword === data.password) {
    return false;
  }
  const response = await api.postbody(`/bots/${data.uuid}/password`, data.password, true);
  return response;
};

// export const setBotRole = (bot, botRight) => async (dispatch, getState, api) => {
const setBotRole = async (bot, state, api) => {
  if (state.bots.selectedBot.appRole === bot.role) {
    return false;
  }

  const roles = await api.get('/roles');
  const botRole = roles.find(role => role.type === 'BOT' && role.name === bot.role);
  const response = await api.put(`/users/${bot.uuid}/roles/${botRole.id}`);
  return response;
};

// if all fail: Error while updating bot details, bot role and password.
// if two fail: Error while updating bot role and password
// if one fails: Error while updating bot details.
const getUpdateError = (response, pwdResponse, roleResponse) => {
  const errorVals = [];
  if (!response.ok) {
    errorVals.push('Bot Details');
  }
  if (roleResponse && !roleResponse.ok) {
    errorVals.push('Bot Role');
  }
  if (pwdResponse && pwdResponse.status && pwdResponse.status !== 200) {
    errorVals.push('Password');
  }

  return errorVals.length ? `Updating ${utils.joinWithAnd(errorVals)} Failed.` : null;
};

export const updateBot = data => async (dispatch, getState, api) => {
  try {
    dispatch(clearBotError());
    const payload = {
      email: data.email ? data.email : `${data.username}@rum.invalid`,
      isPublic: !!data.isPublic,
      isBot: true,
      username: data.username,
      uuid: data.uuid,
    };
    const currentPassword = getState().bots.selectedBot.password;
    const response = await api.put(`/users/${data.uuid}`, payload); // { ...data, isBot: true });
    const pwdResponse = await updateBotPassword(data, currentPassword, api);
    const roleResponse = await setBotRole(data, getState(), api);

    const errorMessage = getUpdateError(response, pwdResponse, roleResponse);
    if (errorMessage) {
      throw errorMessage;
    } else {
      const botDetails = await fetchBotDetail(dispatch, data.uuid, api, getState);
      dispatch(loadBot(botDetails));
      dispatch(updateBotSuccess(response));
    }
  } catch (error) {
    const botDetails = await fetchBotDetail(dispatch, data.uuid, api, getState);
    dispatch(loadBot(botDetails));
    dispatch(botError(error));
  }
};

export const hidePassword = () => async (dispatch) => {
  dispatch({ type: types.HIDE_BOT_PASSWORD_SUCCESS });
};

export const showPassword = bot => async (dispatch) => {
  try {
    dispatch(showPasswordSuccess(bot.password));
  } catch (error) {
    dispatch(showPasswordSuccess(error));
  }
};

export const addUserBot = (user, bot) => async (dispatch, getState, api) => {
  const response = await api.put(`/bots/${bot.uuid}/members/${user.uuid}`);
  const botWithUsers = await fetchBotDetail(dispatch, bot.uuid, api, getState);
  if (response.ok || response.id) {
    if (history.location.pathname.startsWith('/users/')) {
      await dispatch(loadUserDetail(user.uuid));
    } else {
      dispatch(loadBot(botWithUsers));
    }
  } else {
    const id = utils.createUUID();
    dispatch(openModal({ // Dispatch action
      id, // from utils
      type: 'Error', // Need to make in components/modals/Notifications.jsx
      text: 'Could not add user to bot', // error message
      onClose: () => dispatch(loadBot(botWithUsers)), // Fire at close event
      // onConfirm: () => console.log("fire at confirming event"), //Fire at confirm event
    }));
  }
};

export const removeUserBot = (user, bot) => async (dispatch, getState, api) => {
  dispatch(loadingBotMembers());
  const response = await api.delete(`/bots/${bot.uuid}/members/${user.uuid}`);
  if (history.location.pathname.startsWith('/users/')) {
    await dispatch(loadUserDetail(user.uuid));
  }
  const botWithUsers = await fetchBotDetail(dispatch, bot.uuid, api, getState);
  if (response.ok || response.id) {
    dispatch(loadBot(botWithUsers));
  } else {
    const id = utils.createUUID();
    dispatch(openModal({ // Dispatch action
      id, // from utils
      type: 'Error', // Need to make in components/modals/Notifications.jsx
      text: 'Could not remove user from bot', // error message
      onClose: () => dispatch(loadBot(botWithUsers)), // Fire at close event
      // onConfirm: () => console.log("fire at confirming event"), //Fire at confirm event
    }));
  }
};

export const addBotAdmin = (user, bot) => async (dispatch, getState, api) => {
  const roles = await api.get('/roles');
  const botAdminRole = roles.find(role => role.type === 'BOT_PERM' && role.name === 'admin');
  const response = await api.put(`/bots/${bot.uuid}/members/${user.uuid}/roles/${botAdminRole.id}`);
  const botWithUsers = await fetchBotDetail(dispatch, bot.uuid, api, getState);
  if (response.ok || response.id) {
    if (history.location.pathname.startsWith('/users/')) {
      await dispatch(loadUserDetail(user.uuid));
    } else {
      dispatch(loadBot(botWithUsers));
    }
  } else {
    const id = utils.createUUID();
    dispatch(openModal({ // Dispatch action
      id, // from utils
      type: 'Error', // Need to make in components/modals/Notifications.jsx
      text: 'Could not add admin rights for user', // error message
      onClose: () => dispatch(loadBot(botWithUsers)), // Fire at close event
      // onConfirm: () => console.log("fire at confirming event"), //Fire at confirm event
    }));
  }
};

export const removeBotAdmin = (user, bot) => async (dispatch, getState, api) => {
  const roles = await api.get('/roles');
  const botUserRole = roles.find(role => role.type === 'BOT_PERM' && role.name === 'user');
  const response = await api.put(`/bots/${bot.uuid}/members/${user.uuid}/roles/${botUserRole.id}`);
  const botWithUsers = await fetchBotDetail(dispatch, bot.uuid, api, getState);
  if (response.ok || response.id) {
    if (history.location.pathname.startsWith('/users/')) {
      await dispatch(loadUserDetail(user.uuid));
    } else {
      dispatch(loadBot(botWithUsers));
    }
  } else {
    const id = utils.createUUID();
    dispatch(openModal({ // Dispatch action
      id, // from utils
      type: 'Error', // Need to make in components/modals/Notifications.jsx
      text: 'Could not remove admin rights from user', // error message
      onClose: () => dispatch(loadBot(botWithUsers)), // Fire at close event
      // onConfirm: () => console.log("fire at confirming event"), //Fire at confirm event
    }));
  }
};

export const deactivateBot = bot => async (dispatch, getState, api) => {
  try {
    await api.put(`/users/${bot.uuid}/deactivate`, bot);
    const botWithUsers = await fetchBotDetail(dispatch, bot.uuid, api, getState);
    dispatch(loadBot(botWithUsers));
  } catch (error) {
    dispatch(searchBotsFailure(getErrorMessage(error)));
  }
};


export const activateBot = bot => async (dispatch, getState, api) => {
  try {
    await api.put(`/users/${bot.uuid}/activate`, bot);
    const botWithUsers = await fetchBotDetail(dispatch, bot.uuid, api, getState);
    dispatch(loadBot(botWithUsers));
  } catch (error) {
    dispatch(searchBotsFailure(getErrorMessage(error)));
  }
};

export const clearSelectedBot = () => (dispatch) => {
  dispatch({ type: types.CLEAR_BOT_SUCCESS });
};

export const unlockBot = botID => async (dispatch, getState, api) => {
  try {
    await api.put(`/users/${botID}/unlock`);
    const botWithUsers = await fetchBotDetail(dispatch, botID, api, getState);
    dispatch(loadBot(botWithUsers));
  } catch (err) {
    const id = utils.createUUID();
    dispatch(openModal({ // Dispatch action
      id, // from utils
      type: 'Error', // Need to make in components/modals/Notifications.jsx
      text: 'Failed to unlock bot', // error message
      // onClose: () => dispatch(loadBot(botWithUsers)), // Fire at close event
      // onConfirm: () => console.log("fire at confirming event"), //Fire at confirm event
    }));
  }
};
