import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button, Dropdown, Label, Spinner, Input,
} from 'rtm-ui-components';
import * as botsActions from '../../../../store/actions/bots';
import AddBotUsers from './AddBotUsers/AddBotUsers';
import DetailsCard from '../../../SharedComponents/DetailsCard/DetailsCard';
import AddEntityWrapper from '../../../Wrappers/AddEntityWrapper/AddEntityWrapper';
import { userRoleFilters, userActivityFilters } from '../../../../constants';
import HasRole from '../../../../roles/HasRole';
import Table from '../../../SharedComponents/Table/Table';
import AddFirstEntity from '../../../SharedComponents/AddFirstEntity/AddFirstEntity';
import ChangeView from '../../../SharedComponents/ChangeView/ChangeView';
import { icon, entityCardDetails, ucfirst } from '../../../../utils';
import '../../../../assets/stylesheets/DetailsViewContent.css';


class BotUsers extends Component {
  state = {
    filter: '',
    activityFilter: 'active',
    roleFilter: null,
    addUsers: false,
    cards: true,
  }

  componentDidMount() {
    const { getSelectedBotUsers } = this.props;
    const settings = JSON.parse(localStorage.getItem('userSettings'));
    if (settings && settings.detailsListingCards !== undefined && !settings.detailsListingCards) {
      this.setState({ cards: false });
    }
    getSelectedBotUsers();
  }

  onChangeFilter = async (value, type) => {
    if (value === 'all') {
      switch (type) {
        case 'userRole':
          await this.setState({ roleFilter: null });
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case 'userRole':
          await this.setState({ roleFilter: value });
          break;
        default:
          break;
      }
    }
    if (type === 'activity') {
      await this.setState({ activityFilter: value });
    }
  }

  getFilteredUsers = (filter, roleFilter, activityFilter, users) => {
    let filteredUsers = [...users];
    if (!(filteredUsers && (roleFilter || activityFilter))) {
      return filteredUsers;
    }
    if (['user', 'admin', 'sysadmin'].includes(roleFilter)) {
      filteredUsers = filteredUsers.filter(({ user }) => user.appRole === roleFilter);
    }

    if (['active', 'inactive'].includes(activityFilter)) {
      filteredUsers = filteredUsers.filter(({ user }) => user.active === (activityFilter === 'active'));
    }
    if (activityFilter === 'locked') {
      filteredUsers = filteredUsers.filter(({ user }) => user.locked);
    }
    if (filter) {
      filteredUsers = filteredUsers.filter(({ user }) => `${user.firstName} ${user.lastName}`.includes(filter) || user.username.includes(filter));
    }
    return filteredUsers;
  }

  changeListingStyle = async () => {
    const { cards } = this.state;
    let settings = JSON.parse(localStorage.getItem('userSettings'));
    if (!settings) {
      settings = {};
    }
    settings.detailsListingCards = !cards;
    await localStorage.setItem('userSettings', JSON.stringify(settings));
    await this.setState({ cards: !cards });
  }

  toggleModal = () => {
    const { addUsers } = this.state;
    this.setState({ addUsers: !addUsers });
  }

  filterContent = (arrayToFilter) => {
    const filterOptions = arrayToFilter.map((filt) => {
      const tempFilter = { ...filt };
      tempFilter.id = `details-${tempFilter.id}`;
      return tempFilter;
    });
    return filterOptions;
  }

  removeUser = async (user, bot) => {
    const { removeUser, getSelectedBotUsers } = this.props;
    await removeUser(user, bot);
    getSelectedBotUsers();
  }

  addAdmin = async (user, bot) => {
    const { addAdmin, getSelectedBotUsers } = this.props;
    await addAdmin(user, bot);
    getSelectedBotUsers();
  }

  removeAdmin = async (user, bot) => {
    const { removeAdmin, getSelectedBotUsers } = this.props;
    await removeAdmin(user, bot);
    getSelectedBotUsers();
  }

  render() {
    const {
      filter, addUsers, roleFilter, activityFilter, cards,
    } = this.state;
    const {
      bot, getSelectedBotUsers, loading,
    } = this.props;
    const botUsersFiltered = bot && bot.users && this.getFilteredUsers(filter, roleFilter, activityFilter, bot.users);
    let renderedType;
    if ((!bot || !bot.users || !bot.users.length) && loading) {
      renderedType = (
        <div className="details-list-container table">
          <Spinner />
        </div>
      );
    } else if (!bot || !bot.users || !bot.users.length) {
      renderedType = (
        <div className="details-list-container table">
          <AddFirstEntity iconName="addUserIcon" text="There are no users yet." />
        </div>
      );
    } else if (cards) {
      const usersCards = botUsersFiltered.map(({ user, role }) => {
        const details = entityCardDetails(user);
        details.push({
          title: 'User\'s Role',
          value: ucfirst(role.name),
        });
        let actions = [
          {
            id: 'remove-user',
            title: 'Remove user from bot',
            onClick: () => this.removeUser(user, bot),
            requiredRole: 'botEdit',
            resource: bot,
          },
        ];
        if (!user.appRole !== 'ext') {
          actions = [{
            id: role.name === 'user' ? 'add-admin' : 'remove-admin',
            title: role.name === 'user' ? 'Promote to admin' : 'Remove admin',
            onClick: role.name === 'user' ? () => this.addAdmin(user, bot) : () => this.removeAdmin(user, bot),
            requiredRole: 'botEdit',
            resource: bot,
          }, ...actions];
        }
        return (
          <DetailsCard
            key={user.uuid}
            icon={icon(user)}
            name={`${user.lastName} ${user.firstName}`}
            description={user.username}
            details={details}
            link={{ id: 'edit-user', title: 'Edit user', target: `/users/${user.uuid}` }}
            actions={actions}
            isAdmin={role.name === 'admin'}
          />
        );
      });
      renderedType = (
        <div className="details-list-container cards">
          { usersCards }
        </div>
      );
    } else {
      const usersTableContent = botUsersFiltered.map(({ user, role }) => {
        const details = entityCardDetails(user);
        details.push({
          title: 'User\'s Role',
          value: ucfirst(role.name),
        });
        let actions = [
          {
            id: 'remove-user',
            title: 'Remove user from bot',
            onClick: () => this.removeUser(user, bot),
            requiredRole: 'botEdit',
            resource: bot,
          },
        ];
        if (!user.appRole !== 'ext') {
          actions = [{
            id: role.name === 'user' ? 'add-admin' : 'remove-admin',
            title: role.name === 'user' ? 'Promote to admin' : 'Remove admin',
            onClick: role.name === 'user' ? () => this.addAdmin(user, bot) : () => this.removeAdmin(user, bot),
            requiredRole: 'botEdit',
            resource: bot,
          }, ...actions];
        }
        return {
          user, role, details, actions,
        };
      });
      const usersTable = <Table usersPerBot={usersTableContent || null} />;
      renderedType = (
        <div className="details-list-container table">
          { usersTable }
        </div>
      );
    }

    return (
      <div className="details-membership-row" id="bots-page">
        <div className="user-group-form botUsers">
          <div>
            <div className="details-inputs">
              <Input
                value={filter}
                className="user-search"
                id="filter-bots-users-input"
                placeholder="Filter Users"
                onChange={event => this.setState({ filter: event.target.value })}
                icon="search"
                iconPosition="left"
              />
              <div className="details-left">
                <HasRole requiredRole="botEdit" resource={bot}>
                  <Button
                    icon="add"
                    id="add-user-to-bot"
                    iconColor="black"
                    iconPlace="left"
                    variant="primary"
                    onClick={this.toggleModal}
                  >Add Users To Bot
                  </Button>
                </HasRole>
                <ChangeView cards={cards} changeListingStyle={this.changeListingStyle} />
              </div>
            </div>
            <div className="filter-container">
              <div className="filters-wrapper">
                <div>
                  <Label text="User Type" />
                  <Dropdown
                    defaultValue={userRoleFilters[0].text} options={this.filterContent(userRoleFilters)} onChange={item => this.onChangeFilter(item.value, 'userRole')}
                  />
                </div>
                <div>
                  <Label text="User Status" />
                  <Dropdown
                    defaultValue={userActivityFilters[0].text} options={this.filterContent(userActivityFilters)} onChange={item => this.onChangeFilter(item.value, 'activity')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="details-entity-listing">
            { renderedType }
          </div>
          {addUsers
            ? (
              <AddEntityWrapper
                onClose={this.toggleModal}
                open={addUsers}
                title="Add Users to Bot"
              >
                <AddBotUsers
                  addAction={async () => {
                    await getSelectedBotUsers();
                    this.toggleModal();
                  }}
                  selectedBot={bot}
                />
              </AddEntityWrapper>
            ) : null}
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => (bindActionCreators({
  getSelectedBotUsers: botsActions.getSelectedBotUsers,
  removeUser: botsActions.removeUserBot,
  addAdmin: botsActions.addBotAdmin,
  removeAdmin: botsActions.removeBotAdmin,
}, dispatch));

const mapStateToProps = state => ({
  loading: state.bots.isSearchingBotMembers,
});

export default connect(mapStateToProps, mapDispatchToProps)(BotUsers);
